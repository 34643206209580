import '../styles/MentionsLegales.scss'

import React from 'react'
import ReactMarkdown from 'react-markdown'
import {graphql} from 'gatsby'

function MentionsLegales({data}) {
  const markdownFromContentful =
    data.allContentfulMentionsLegales.edges[0].node.mentionsLegales
      .mentionsLegales

  console.log(markdownFromContentful)

  return (
    <div className="mentionslegales-container">
      <div className="text-container">
        <div className="text-inner">
          <ReactMarkdown
            children={markdownFromContentful}
            className="reactMarkDown"
          />
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query MentionsLegalesQuery {
    allContentfulMentionsLegales {
      edges {
        node {
          mentionsLegales {
            mentionsLegales
          }
        }
      }
    }
  }
`

export default MentionsLegales
